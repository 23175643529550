<template>
<div>
    <div v-if="finalized" class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-12">
                <!-- Deliquent alert -->
                <div
                    v-if="$_userMixins_isSchoolAdmin && ($_userMixins_isDelinquent || $_userMixins_isDisabled)"
                    class="alert fade show"
                    :class="$_userMixins_isDisabled ? 'alert-danger' : 'alert-warning'"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="flaticon-warning" />
                    </div>
                    <div class="alert-text">
                        {{ user.access.schoolSubscriptionMessage }}
                    </div>
                    <div class="alert-close">
                        <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-label="Close"
                        >
                            <span aria-hidden="true"><i class="la la-close" /></span>
                        </button>
                    </div>
                </div>
                <!-- User Header -->
                <div class="kt-portlet">
                    <div class="kt-portlet__body">
                        <div
                            v-if="unsupportedTerm"
                            class="alert alert-outline-danger w-100 pb-4"
                            role="alert"
                        >
                            <div class="alert-icon">
                                <i class="flaticon-warning" />
                            </div>
                            <div class="alert-text kf-font-bold">
                                This term may no longer be backwards compatible with the current version of SyncGrades. Please only use it for viewing purposes.
                            </div>
                        </div>
                        <div
                            :key="`ref_${key}`"
                            class="row"
                        >
                            <div class="col-md-6">
                                <div v-if="$_userMixins_isSchoolUser" class="kt-section">
                                    <div class="kt-section__title mb-2">
                                        {{ user.firstName }} {{ user.lastName }}
                                    </div>
                                    <div class="kt-section__desc">
                                        <span class="btn btn-label btn-label-brand btn-sm btn-bold sg-role-badge">{{ user.school.role }}</span>
                                        {{ user.userName }}
                                    </div>
                                    <div
                                        v-if="user.googleEmail && !$_userMixins_isGoogleDisabled"
                                        class="kt-section__content"
                                    >
                                        {{ $t('pages.home.googleClassroomAccount') }}: {{ user.googleEmail }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="kt-section">
                                    <div class="kt-section__title mb-2">
                                        {{ user.school.externalSchoolId }} {{ user.school.schoolName }}
                                    </div>
                                    <div class="kt-section__desc">
                                        {{ user.school.name }}
                                    </div>
                                    <div class="kt-section__desc">
                                        {{ termStartDate }}
                                        -
                                        {{ termEndDate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <HomeStudents
                    v-if="!$_userMixins_isDisabled && $_userMixins_isHomeUser"
                />
            </div>
        </div>
        <div
            v-if="!$_userMixins_isDisabled && $_userMixins_isTeacher"
            class="row"
        >
            <div
                v-for="course in teacherCourses"
                :key="`course_${course.extCourseSectionId}`"
                class="col-xl-4 col-lg-6 col-md-6 col-sm-12"
            >
                <CourseShortcuts :course="course" />
            </div>
        </div>
        <div
            v-if="!$_userMixins_isHomeUser"
            class="row"
        >
            <div class="col-lg-6">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <span class="kt-portlet__head-icon  kt-font-primary">
                                <i class="la la-weixin" />
                            </span>
                            <h3 class="kt-portlet__head-title">
                                SyncGrades Community Portal
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <a
                                v-if="joinedCommunity"
                                target="_blank"
                                :href="feedbackUrl"
                                class="btn btn-clean btn-pill btn-sm btn-icon-md"
                            >
                                Open Full Site
                            </a>
                        </div>
                    </div>

                    <div
                        v-if="joinedCommunity"
                        class="kt-portlet__body"
                    >
                        <div data-upvoty>
                            <div id="upvoty-placeholder" />
                        </div>
                    </div>
                    <div
                        v-else
                        class="kt-portlet__body p-5"
                    >
                        <div class="row">
                            <div class="col-12 text-center">
                                <a
                                    href="#"
                                    class="btn btn-primary btn-lg"
                                    @click.stop.prevent="unlockCommunity"
                                >
                                    <i class="la la-weixin" />
                                    Join Community Portal
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="pt-5 d-md-block d-lg-none d-xl-none" />
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <span class="kt-portlet__head-icon  kt-font-primary">
                                <i class="la la-clock-o" />
                            </span>
                            <h3 class="kt-portlet__head-title">
                                Recent Changes
                            </h3>
                        </div>
                    </div>c
                    <div class="kt-portlet__body pt-0">
                        <div class="markdown-container">
                            <VueShowdown
                                v-if="releaseNotes && $_userMixins_isSchoolUser"
                                :markdown="releaseNotes"
                                flavor="github"
                                class="markdown"
                                :options="{ emoji: true, headerLevelStart: 2 }"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
// @ts-ignore
import { VueShowdown } from 'vue-showdown';
import * as network from '../../network';
import HomeStudents from '../../components/HomeStudents.vue';
import CourseShortcuts from '../../components/CourseShortcuts.vue';
// @ts-ignore
import userMixins from '../../store/mixins/userMixins';
import { getCourses, CourseFilter } from '../../store/mixins/courseMixins';
import upvoty from './upvoty';

export default {
    name: 'HomePage',
    components: {
        VueShowdown,
        CourseShortcuts,
        HomeStudents,
    },
    mixins: [userMixins],
    data() {
        return {
            key: 0,
            saving: false,
            releaseNotes: '',
            joinedCommunity: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            userRole: (state) => state.user.school.role,
            noGoogleAccount: (state) => Boolean(state.user.googleAccountId === 0 && !['Student', 'Guardian'].includes(state.user.school.role)),
            isSuperAdmin: (state) => state.user.isSuperAdmin,
            userPermissions: (state) => state.user.userPermissions,
        }),
        finalized() {
            return this.$store.state.user.school.finalized;
        },
        termStartDate() {
            const { termStart } = this.user.school;
            return moment(String(termStart).substring(0, 10)).format('MMM Do YYYY');
        },
        termEndDate() {
            const { termEnd } = this.user.school;
            return moment(String(termEnd).substring(0, 10)).format('MMM Do YYYY');
        },
        isSchoolAdmin() {
            return this.user.school.role === 'School Admin';
        },
        canAccessBilling() {
            return this.isSuperAdmin || this.userPermissions.canManagePurchaseOrders || this.isSchoolAdmin;
        },
        unsupportedTerm() {
            const oldYear = this.user.school.schoolYear;
            if (this.user.school.externalSchoolId == '00X000') return false;
            if (!oldYear) return false;
            if (parseInt(oldYear, 10) <= 2022) return true;
            return false;
        },
        feedbackUrl() {
            const { schoolId, schoolTermId } = this.user.school;
            return `/api/schools/${schoolId}/terms/${schoolTermId}/feedback/redirect`;
        },
        teacherCourses() {
            if (!this.$_userMixins_isTeacher) return [];
            const { database } = this.$store.state;
            const { schoolStaffId } = this.user.school;
            const courses = getCourses(database, new CourseFilter({ schoolStaffId }));
            return courses.filter((x) => !x.hideFromNav && !x.deleted);
        },
    },
    mounted() {
        const v = this;
        // hide nav menus if subscription is disabled
        if (!this.$_userMixins_isSchoolUser) return;
        if (v.user.access.disabled) return;
        setTimeout(() => {
            if (v.finalized == false) {
                v.$router.push(({ path: `/configuration/wizard/SetupDataUploads` }));
            }
        }, 200);

        if (localStorage.getItem('joinedCommunity') == 'true') {
            this.joinedCommunity = true;
        }
        v.populate();
    },
    methods: {
        populate() {
            const { initiateChangeLogs, joinedCommunity } = this;
            const { showError, unlockCommunity, } = this;
            if (this.$_userMixins_isHomeUser) return;

            initiateChangeLogs((err) => {
                if (err) return showError(err);
            });

            if (!joinedCommunity) return;
            setTimeout(() => {
                unlockCommunity();
            }, 100);

        },
        unlockCommunity() {
            this.joinedCommunity = true;
            localStorage.setItem('joinedCommunity', 'true');
            const { initiateUpvoty, showError } = this;
            initiateUpvoty((err2) => {
                if (err2) return showError(err2);
            });
        },
        initiateUpvoty(callback) {
            const { schoolId, schoolTermId } = this.user.school;
            const params = { url: { schoolId, schoolTermId } };

            network.auth.upsertFeedbackAccount(params, (err) => {
                if (err) return callback(err);
                const d = document.querySelectorAll('div[data-upvoty]')[0];
                if (!d) { return callback(); }
                upvoty.destroy();
                upvoty.init('render', {
                    boardHash: 'f18e55c77f252af419813ce6fd840f828e93c1edf91c7e577c3ca6ef423a0ed2',
                    ssoToken: null,
                    baseUrl: 'feedback.syncgrades.com',
                });
                callback();
            });
        },
        initiateChangeLogs(callback) {
            const v = this;
            const { schoolId, schoolTermId } = this.user.school;
            const params = { url: { schoolId, schoolTermId } };
            network.server.getChangeLogs(params, (err, res) => {
                if (err) return callback(err);
                v.releaseNotes = res.changeLog;
                callback();
            });
        },
    },
};
</script>

<style scoped>
.btn.sg-role-badge {
   line-height: 0.7em;
}
.col-xl-6 .kt-portlet {
    min-height: 100%;
}

</style>
