<template>
<div class="kt-portlet">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                <CourseIcon
                    :course="course"
                    size="xs"
                    class="mt-0 mr-2"
                />
                {{ course.courseSectionTitle || course.name }}
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar d-none">
            Tool
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="kt-section">
            <div class="kt-section__content kt-section__content--border kt-section__content--fit">
                <ul class="kt-nav kt-nav--md-space">
                    <li
                        v-for="item in items"
                        :key="`course_nav_${item.icon}`"
                        class="kt-nav__item"
                    >
                        <router-link
                            :to="{
                                name: item.route.name,
                                params: routeParameters,
                            }"
                            class="kt-nav__link"
                        >
                            <SVGIcon
                                class="mr-2 kt-svg-icon-custom"
                                :name="item.icon"
                                style="opacity: 0.6;"
                                :hex-color="courseColor()"
                            />
                            <span class="kt-nav__link-text">
                                {{ item.title }}
                            </span>
                            <span class="kt-nav__link-badge d-none">
                                <span class="kt-badge kt-badge--success">5</span>
                            </span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import CourseIcon from './CourseIcon.vue';
import SVGIcon from './SVGIcon/SVGIcon.vue';
import courseMixins from '../store/mixins/courseMixins';
import userMixins from '../store/mixins/userMixins';

export default Vue.extend({
    name: 'CourseInfo',
    components: {
        CourseIcon,
        SVGIcon,
    },
    mixins: [
        courseMixins,
        userMixins,
    ],
    props: {
        course: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            items: [{
                route: { name: 'TeacherCourseRosters' },
                icon: 'students',
                hovered: false,
                title: 'Rosters',
            }, {
                route: { name: 'TeacherCourseAttendance' },
                icon: 'attendance',
                hovered: false,
                title: 'Attendance',
            }, {
                route: { name: 'TeacherCourseAssignments' },
                icon: 'assignments',
                hovered: false,
                title: 'Assignments',
            }, {
                route: { name: 'TeacherCourseGradebook' },
                icon: 'gradebook',
                hovered: false,
                title: 'Gradebook',
            }, {
                route: { name: 'TeacherCourseAverages' },
                icon: 'averages',
                hovered: false,
                title: 'Averages',
            }, {
                route: { name: 'TeacherCourseReports' },
                icon: 'progressReports',
                hovered: false,
                title: 'Reports',
            }],
            color: '#c4cff9',
            hoverColor: '#0f88ef',
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        routeParameters() {
            const { extCourseSectionId } = this.course;
            const schoolEmail = this.$_userMixins_schoolEmail;
            return { extCourseSectionId, schoolEmail };
        },
    },
    watch: {
    },
    methods: {
        courseColor() {
            return this.$_courseMixins_getHexColorForCourse(this.course);
        },
    },
});
</script>
