var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "kt-portlet" }, [
    _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c(
          "h3",
          { staticClass: "kt-portlet__head-title" },
          [
            _c("CourseIcon", {
              staticClass: "mt-0 mr-2",
              attrs: { course: _vm.course, size: "xs" },
            }),
            _vm._v(
              " " +
                _vm._s(_vm.course.courseSectionTitle || _vm.course.name) +
                " "
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "kt-portlet__head-toolbar d-none" }, [
        _vm._v(" Tool "),
      ]),
    ]),
    _c("div", { staticClass: "kt-portlet__body" }, [
      _c("div", { staticClass: "kt-section" }, [
        _c(
          "div",
          {
            staticClass:
              "kt-section__content kt-section__content--border kt-section__content--fit",
          },
          [
            _c(
              "ul",
              { staticClass: "kt-nav kt-nav--md-space" },
              _vm._l(_vm.items, function (item) {
                return _c(
                  "li",
                  {
                    key: `course_nav_${item.icon}`,
                    staticClass: "kt-nav__item",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "kt-nav__link",
                        attrs: {
                          to: {
                            name: item.route.name,
                            params: _vm.routeParameters,
                          },
                        },
                      },
                      [
                        _c("SVGIcon", {
                          staticClass: "mr-2 kt-svg-icon-custom",
                          staticStyle: { opacity: "0.6" },
                          attrs: {
                            name: item.icon,
                            "hex-color": _vm.courseColor(),
                          },
                        }),
                        _c("span", { staticClass: "kt-nav__link-text" }, [
                          _vm._v(" " + _vm._s(item.title) + " "),
                        ]),
                        _c(
                          "span",
                          { staticClass: "kt-nav__link-badge d-none" },
                          [
                            _c(
                              "span",
                              { staticClass: "kt-badge kt-badge--success" },
                              [_vm._v("5")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }