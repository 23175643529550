<template>
<div :key="`key_${key}`">
    <div
        v-if="students"
        class="row"
    >
        <div
            v-for="(student, idx) in students"
            :key="`student_${student.studentEnrollmentId}_${idx}`"
            class="col-xl-3 col-lg-4 col-md-6 col-xs-12"
        >
            <StudentListItem :student="student" />
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import UserAvatar from './UserAvatar.vue';
import StudentCourseAverage from './StudentCourseAverage.vue';
import studentMixins from '../store/mixins/studentMixins';
import utilMixins from '../store/mixins/utilMixins';
import StudentListItem from './StudentListItem.vue';

export default {
    name: 'HomeStudents',
    components: {
        UserAvatar,
        StudentCourseAverage,
        StudentListItem,
    },
    mixins: [
        studentMixins,
        utilMixins,
    ],
    data() {
        return {
            rosterResults: [],
            key: 0,
        };
    },
    computed: {
        ...mapState({
            school: (state) => state.user.school,
        }),
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        students() {
            const { school } = this;
            const { schoolTermId } = school;
            const students = this.$_studentMixins_getStudents();
            return students
                .filter((student) => student.schoolTermId == schoolTermId);
        },
    },
    watch: {
        schoolTermMarkingPeriodId() {
            this.key += 1;
        },
    },
    methods: {
    },
};
</script>

<style scoped>
.btn-wide {
    white-space: nowrap;
}

.kt-widget__img_sg {
    border-radius: 50%;
    width: 90px;
    min-height: 90px;
}
div.kt-widget__description span {
    width: 100%;
    text-overflow: clip;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    display: inline-block;
}
</style>
