var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: `key_${_vm.key}` }, [
    _vm.students
      ? _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.students, function (student, idx) {
            return _c(
              "div",
              {
                key: `student_${student.studentEnrollmentId}_${idx}`,
                staticClass: "col-xl-3 col-lg-4 col-md-6 col-xs-12",
              },
              [_c("StudentListItem", { attrs: { student: student } })],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }