var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.finalized
      ? _c(
          "div",
          {
            staticClass:
              "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _vm.$_userMixins_isSchoolAdmin &&
                  (_vm.$_userMixins_isDelinquent || _vm.$_userMixins_isDisabled)
                    ? _c(
                        "div",
                        {
                          staticClass: "alert fade show",
                          class: _vm.$_userMixins_isDisabled
                            ? "alert-danger"
                            : "alert-warning",
                          attrs: { role: "alert" },
                        },
                        [
                          _vm._m(0),
                          _c("div", { staticClass: "alert-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.user.access.schoolSubscriptionMessage
                                ) +
                                " "
                            ),
                          ]),
                          _vm._m(1),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "kt-portlet" }, [
                    _c("div", { staticClass: "kt-portlet__body" }, [
                      _vm.unsupportedTerm
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "alert alert-outline-danger w-100 pb-4",
                              attrs: { role: "alert" },
                            },
                            [
                              _vm._m(2),
                              _c(
                                "div",
                                { staticClass: "alert-text kf-font-bold" },
                                [
                                  _vm._v(
                                    " This term may no longer be backwards compatible with the current version of SyncGrades. Please only use it for viewing purposes. "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("div", { key: `ref_${_vm.key}`, staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-6" }, [
                          _vm.$_userMixins_isSchoolUser
                            ? _c("div", { staticClass: "kt-section" }, [
                                _c(
                                  "div",
                                  { staticClass: "kt-section__title mb-2" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.user.firstName) +
                                        " " +
                                        _vm._s(_vm.user.lastName) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "kt-section__desc" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "btn btn-label btn-label-brand btn-sm btn-bold sg-role-badge",
                                    },
                                    [_vm._v(_vm._s(_vm.user.school.role))]
                                  ),
                                  _vm._v(" " + _vm._s(_vm.user.userName) + " "),
                                ]),
                                _vm.user.googleEmail &&
                                !_vm.$_userMixins_isGoogleDisabled
                                  ? _c(
                                      "div",
                                      { staticClass: "kt-section__content" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "pages.home.googleClassroomAccount"
                                              )
                                            ) +
                                            ": " +
                                            _vm._s(_vm.user.googleEmail) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "kt-section" }, [
                            _c(
                              "div",
                              { staticClass: "kt-section__title mb-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.user.school.externalSchoolId) +
                                    " " +
                                    _vm._s(_vm.user.school.schoolName) +
                                    " "
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "kt-section__desc" }, [
                              _vm._v(" " + _vm._s(_vm.user.school.name) + " "),
                            ]),
                            _c("div", { staticClass: "kt-section__desc" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.termStartDate) +
                                  " - " +
                                  _vm._s(_vm.termEndDate) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  !_vm.$_userMixins_isDisabled && _vm.$_userMixins_isHomeUser
                    ? _c("HomeStudents")
                    : _vm._e(),
                ],
                1
              ),
            ]),
            !_vm.$_userMixins_isDisabled && _vm.$_userMixins_isTeacher
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.teacherCourses, function (course) {
                    return _c(
                      "div",
                      {
                        key: `course_${course.extCourseSectionId}`,
                        staticClass: "col-xl-4 col-lg-6 col-md-6 col-sm-12",
                      },
                      [_c("CourseShortcuts", { attrs: { course: course } })],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            !_vm.$_userMixins_isHomeUser
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("div", { staticClass: "kt-portlet" }, [
                      _c("div", { staticClass: "kt-portlet__head" }, [
                        _vm._m(3),
                        _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                          _vm.joinedCommunity
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-clean btn-pill btn-sm btn-icon-md",
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.feedbackUrl,
                                  },
                                },
                                [_vm._v(" Open Full Site ")]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _vm.joinedCommunity
                        ? _c("div", { staticClass: "kt-portlet__body" }, [
                            _vm._m(4),
                          ])
                        : _c("div", { staticClass: "kt-portlet__body p-5" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12 text-center" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-primary btn-lg",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.unlockCommunity.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "la la-weixin" }),
                                    _vm._v(" Join Community Portal "),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("div", {
                      staticClass: "pt-5 d-md-block d-lg-none d-xl-none",
                    }),
                    _c("div", { staticClass: "kt-portlet" }, [
                      _vm._m(5),
                      _vm._v("c "),
                      _c("div", { staticClass: "kt-portlet__body pt-0" }, [
                        _c(
                          "div",
                          { staticClass: "markdown-container" },
                          [
                            _vm.releaseNotes && _vm.$_userMixins_isSchoolUser
                              ? _c("VueShowdown", {
                                  staticClass: "markdown",
                                  attrs: {
                                    markdown: _vm.releaseNotes,
                                    flavor: "github",
                                    options: {
                                      emoji: true,
                                      headerLevelStart: 2,
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-warning" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert-close" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "alert",
            "aria-label": "Close",
          },
        },
        [
          _c("span", { attrs: { "aria-hidden": "true" } }, [
            _c("i", { staticClass: "la la-close" }),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-warning" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("span", { staticClass: "kt-portlet__head-icon kt-font-primary" }, [
        _c("i", { staticClass: "la la-weixin" }),
      ]),
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" SyncGrades Community Portal "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { "data-upvoty": "" } }, [
      _c("div", { attrs: { id: "upvoty-placeholder" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("span", { staticClass: "kt-portlet__head-icon kt-font-primary" }, [
          _c("i", { staticClass: "la la-clock-o" }),
        ]),
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Recent Changes "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }